.Footer {
    background-image: linear-gradient(to bottom, #535251, #484747, #3e3d3d, #333333, #292929);
    width: 100%;
}

.Footer-content {
    padding: 2% 5%;
    max-width: 1200px;
    margin: 0 auto;
}

.Footer-Info {
    display: flex;
    flex-direction: row;
    color: white;
    padding: 40px 0 20px 0;
}

.Footer-CompanyInfo {
    display: flex;
    flex-direction: row;
    padding: 30px 0;
    justify-content: center;
}

.Footer-leftBlock {
    flex: 1;
    padding: 0 15px;
    border-right: 1px solid #454444;

}

.Footer-rightBlock {
    flex: 1;

}

.Footer-centerBlock {
    flex: 1;

    align-items: center;
}

.Footer-CompanyName {
    padding: 17px 5px;
    color: white;
    font-family: 'Montserrat';

}

.Footer-CompanyName div {
    padding: 2px 0;
    font-weight: 100;
}

.Footer-icon {
    padding: 0 10px;
    font-size: 22px;
    color: white;
}

.Footer-leftBlock2 {
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 0px;
    font-family: 'Montserrat';
}

.Footer-centerBlock2 {
    display: flex;
    flex-direction: row;
    max-width: 350px;
    padding: 10px 0;
    margin: 0 auto;
}

.Footer-headers {
    font-size: 18px;
    text-align: center;
    color: white;
    padding: 0px 0 20px 0;
}

.Footer-centerBlock3 {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    padding: 10px 0;
}

.Footer-AddrHighlist{
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
}

.Footer-Addrtext{
    font-size: 14px;
}

@media only screen and (max-width: 800px) {
    .Footer-Info {
        flex-direction: column;
    }

    .Footer-centerBlock2 {
        max-width: 400px;
        font-size: 14px;
        font-family: 'Montserrat';
        font-weight: 100;
        color: #d0d0d0;
    }

    .Footer-centerBlock {
        padding: 40px 0px 0 0;

    }

    .Footer-leftBlock2 {
        padding: 0px 0px 40px 0px;
        font-size: 15px;
        font-family: 'Montserrat';
        font-weight: 100;
        color: #d0d0d0;
    }

    .Footer-CompanyInfo {

        padding: 5% 5%;

    }

    .Footer-leftBlock {
        border-right: 0px;
    }

}