.Ven-Info{
    max-width: 1000px;
display: flex;
justify-content: center;
flex-direction: row;
flex-wrap: wrap;
margin: 0 auto;
}
.Ven-Blocks{
width: 350px;
margin: 15px 10px;
padding: 20px 10px;
}
.Ven-Imgs{
position: relative;
height: 150px;
width: 150px;
margin: 0 auto;
}
.Ven-Name{
font-family: 'Montserrat';
padding: 15px 15px;
}
.Ven-Name p{
margin: 20px 0;
}
@media only screen and (max-width: 500px) {
.Ven-Imgs{
height: 125px;
width: 125px;
}
}