.Header {
    background-color: rgba(255, 255, 255);
    height: 90px;
    width: 100%;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.10);
    position: fixed;
    z-index: 100;
}

.Links {
    text-decoration: none;
    color: black;
    -webkit-tap-highlight-color: transparent;
    padding: 10px 15px;
}

.Links2 {
    text-decoration: none;
    color: black;
    -webkit-tap-highlight-color: transparent;
}

.Links:hover {
    color: #9ea2d9;
    cursor: pointer;
}

.linkCon {
    margin: 0px 10px;

}

.HeaderCont {
    padding: 0 10%;

}

.CName {
    font-size: 10px;
}

.Mailtxt {
    padding: 0 4px;
}

.SeperatorMil {
    padding: 0 15px;
}

.clickable {
    display: flex;
    align-items: center;
}

.clickable:hover {
    cursor: pointer;
    color: #0066cc;
}

.titleCOnt {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    padding: 0 10px;
    font-size: 22px;
}

.ContactInfo {
    background-color: #fafafa;
    font-size: 14px;
    display: flex;
    padding: 2px 0px;
    align-items: center;
    justify-content: center;
    color: #494949;
}

.menuCon {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.menuConButton {
    display: flex;
    align-items: center;
}


.headCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: center;
    height: 70px;
}

.imgCon {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.menuConButton {
    display: none;
}

.HamIcon {
    font-size: 22px;
    padding: 0 10px;
    -webkit-tap-highlight-color: transparent;
}

.HamIcon:hover {
    cursor: pointer;
}

.m-menuCon {
    position: fixed;
    top: 90px;
    left: 0;
    background-color: white;
    z-index: 90;
    width: 100%;
    display: none;
    -webkit-box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.10);
}

.linkCon-m {
    margin: 0px 10px;
    -webkit-tap-highlight-color: transparent;
    padding: 10px 15px;
    border-bottom: 1px solid whitesmoke;
    font-weight: 300;
}

.LinksForContact {
    text-decoration: none;
    color: #494949;
    -webkit-tap-highlight-color: transparent;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1250px) {
    .HeaderCont {
        padding: 0 5%;
    }

    .linkCon {
        margin: 0px 7px;
        padding: 10px 8px;
        font-size: 14px;
    }

    .titleCOnt {
        font-size: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .menuCon {
        display: none;
    }

    .HeaderCont {
        padding: 0 3%;
    }

    .titleCOnt {
        padding: 0 20px;
    }

    .menuConButton {
        display: block;
    }

    .ContactInfo {
        font-weight: 400;
    }

    .titleCOnt {
        font-size: 17px;
        padding: 0 7px;
    }

    .ContactInfo {
        padding: 5px 0px;
    }

    .m-menuCon {
        display: block;
    }
}