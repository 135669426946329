.mainblock {}

.firstblock {
    background-image: linear-gradient(to top, #ffffff, #fcfcfc, #f8f8f8, #f5f5f5, #f2f2f2);

}

.FB-Content {
    padding: 0 10%;
}

.FB-Info {
    padding: 7% 0%;
    text-align: center;
}

.FB-Info-title {
    font-size: 45px;
    font-weight: 100;
    font-family: 'Montserrat';
    padding: 2% 0 1% 0;
}

.FB-Info-desc {
    font-size: 20px;
    font-weight: 100;
    padding: 1% 0 2% 0;
    max-width: 600px;
    margin: 0 auto;
}

.FB-Info-button {
    padding: 2% 0%;
}

.FB-Info-button button {
    background-color: #6286e3;
    border: 0px;
    padding: 10px 24px;
    font-size: 18px;
    font-weight: 300;
    color: white;
    border-radius: 4px;
}

.FB-Info-button button:hover {
    -webkit-box-shadow: 0px 2px 9px -2px rgba(98, 134, 227, 0.20);
    -moz-box-shadow: 0px 2px 9px -2px rgba(98, 134, 227, 0.20);
    box-shadow: 0px 2px 9px -2px rgba(98, 134, 227, 0.20);
    cursor: pointer;
}

.secondblock {
    background-color: white;
    padding: 5% 0;
}

.SB-Content {
    padding: 0 10%;
    text-align: center;

}

.SB-Block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.SB-Blocks {
    background-color: white;
    width: 350px;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.20);
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.20);
    border-radius: 5px;
    padding: 6% 2%;
    margin: 15px 20px;
}

.SB-Icon {
    font-size: 45px;
    padding: 10px 0 20px 0;
    color: #434343;
}

.SB-Title {
    font-size: 25px;
    font-weight: 100;
    font-family: 'Montserrat';
    padding: 2% 0 2% 0;
}

.SB-Desc {
    font-size: 18px;
    font-weight: 100;
    padding: 2% 0 2% 0;
}

.SB-Info-title {
    font-size: 40px;
    font-weight: 100;
    font-family: 'Montserrat';
    padding: 2% 0 4% 0;
}

.thirdblock {
    background-color: #f7f7f7;
}

.TB-Content {
    padding: 5% 0;
    text-align: center;
}

.TB-Info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
}

.TB-blocks {
    position: relative;
    border-radius: 5px;
    margin: 10px 20px;

}

.TB-displayText {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    padding: 0 0 8px 5px;
    font-weight: 300;
    font-size: 18px;
    font-family: 'Montserrat';
}

.TB-displayBack {
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 150px;
}

.TB-Imgs {
    width: 150px;
    height: 150px;
    border-radius: 5px;
}

.FthB-Imgs {
    border-radius: 5px;
    background-color: white;
    width: 150px;
    height: 100px;
    object-fit: contain;
}

.FthB-Info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.formContainer {
    max-width: 500px;
    padding: 20px 30px;
    margin: 3% auto;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.20);
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.20);
    border-radius: 15px;
}

.formTitle {
    text-align: center;
    font-size: 22px;
    font-family: 'Montserrat';
    padding: 10px 0;
}

.inputContainer {
    margin: 15px 5px 10px 5px;
}

.inputContainer label {
    margin: 10px 0px;
    font-family: 'Montserrat';
}

.inputContainer input,
textarea {
    padding: 10px 20px;
    margin-top: 10px;
    width: 90%;
    font-family: 'Montserrat';
}

.formSubmit {
    background-color: #6286e3;
    border: 0px;
    margin: 20px 0;
    padding: 10px 24px;
    font-size: 18px;
    width: 100%;
    font-weight: 300;
    color: white;
    border-radius: 4px;
}

.formSubmit:hover {
    -webkit-box-shadow: 0px 2px 9px -2px rgba(98, 134, 227, 0.20);
    -moz-box-shadow: 0px 2px 9px -2px rgba(98, 134, 227, 0.20);
    box-shadow: 0px 2px 9px -2px rgba(98, 134, 227, 0.20);
    cursor: pointer;
}

.PNF-Container {
    margin-top: 90px;
    height: 600px;
    text-align: center;
}

.PNF-INfo {
    width: 100%;
    position: absolute;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'Montserrat';
}

.PNF-tile {
    font-size: 72px;
}

@media only screen and (max-width: 1250px) {
    .FB-Info {
        padding: 10% 0%;

    }

    .FB-Info-title {
        font-size: 40px;
        padding: 4% 0 2% 0;
    }

    .FB-Info-desc {
        padding: 2% 0 4% 0;
        font-size: 18px;
        max-width: 550px;
    }

    .FB-Info-button {
        padding: 4% 0%;
    }

    .FB-Info-button button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .SB-Blocks {
        padding: 10% 4%;
        max-width: 300px;
    }

}

@media only screen and (max-width: 800px) {

    .FB-Info-title {
        font-size: 35px;
        padding: 5% 0 3% 0;
    }

    .FB-Info-desc {
        padding: 3% 0 5% 0;
        font-size: 17px;
        max-width: 550px;
        font-weight: 200;
    }

    .FB-Info-button {
        padding: 4% 0%;
    }

    .FB-Info-button button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .SB-Blocks {
        padding: 10% 2%;
    }

    .SB-Blocks {
        padding: 10% 6%;
        max-width: 300px;
    }

    .SB-Title {
        font-size: 22px;
        padding: 4% 0 4% 0;
    }

    .SB-Desc {
        font-size: 17px;
    }

    .SB-Info-title {
        font-size: 36px;
    }

    .TB-blocks {
        margin: 10px 12px;
    }

    .TB-displayText {
        font-size: 17px;
    }

    .formContainer {
        max-width: 350px;
        padding: 10px 20px;
        margin: 5% auto;

    }

    .inputContainer input,
    textarea {
        padding: 10px 10px;
        margin-top: 10px;
        width: 92%;
        font-family: 'Montserrat';
    }

    .PNF-INfo {

        top: 50%;

    }

    .PNF-Container {
        height: 450px;

    }
}

@media only screen and (max-width: 500px) {
    .FB-Content {
        padding: 0 5%;
    }

    .FB-Info-title {
        font-size: 28px;
        padding: 7% 2% 5% 2%;
    }

    .FB-Info-desc {
        padding: 5% 0 7% 0;
        font-size: 18px;
        max-width: 550px;
        font-weight: 200;
    }

    .FB-Info-button {
        padding: 4% 0%;
    }

    .FB-Info-button button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .SB-Blocks {
        padding: 15% 10%;
        width: 90%;
        -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.10);
        -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.10);
        box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.10);
    }

    .SB-Info-title {
        font-size: 30px;
        padding: 35px 0px;
    }

    .TB-blocks {
        margin: 8px 10px;
    }

    .TB-Imgs {
        width: 125px;
        height: 125px;
        border-radius: 5px;
    }

    .TB-displayBack {
        height: 125px;
        width: 125px;
    }

    .TB-displayText {
        font-size: 16px;
    }

    .FthB-Imgs {
        border-radius: 5px;
        background-color: white;
        max-width: 125px;
    }

    .formContainer {
        width: 300px;


    }
}